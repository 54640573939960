import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from '../hooks/use-search-params';

export type ArticleFilterContextType = {
  activeFilter: string[] | null;
  searchParam: string | null;
  activeFilterPracticeMap: string[] | null;
  scrollingPos: number | null;
  setActiveFilterPracticeMap: Dispatch<SetStateAction<string[] | null>>;
  setSearchParams: (queryParam: string, activeFilters: string[]) => void;
  setScrollingPos: Dispatch<SetStateAction<number | null>>;
};

export const ArticleFilterContext =
  createContext<ArticleFilterContextType | null>(null);

export const useArticleFilterContext = () => useContext(ArticleFilterContext);

const ArticleFilterContextProvider = ({ children }: PropsWithChildren) => {
  const [activeFilter, setActiveFilterState] = useState<string[]>([]);
  const [activeFilterPracticeMap, setActiveFilterPracticeMap] = useState<
    string[] | null
  >(null);
  const [searchParam, setSearchParamState] = useState('');
  const [scrollingPos, setScrollingPos] = useState<number | null>(null);
  const urlSearchParams = useSearchParams(true);

  const setSearchParams = (queryParam: string, activeFilters: string[]) => {
    setSearchParamState(queryParam);
    setActiveFilterState(activeFilters);
    urlSearchParams.set({
      filterIds: activeFilters,
      q: queryParam,
    });
  };

  useEffect(() => {
    const storedScrollingPos = sessionStorage.getItem('scrollingPos');
    if (storedScrollingPos) {
      setScrollingPos(JSON.parse(storedScrollingPos));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('scrollingPos', JSON.stringify(scrollingPos));
  }, [scrollingPos]);

  return (
    <ArticleFilterContext.Provider
      value={{
        activeFilter,
        searchParam,
        activeFilterPracticeMap,
        scrollingPos,
        setSearchParams,
        setActiveFilterPracticeMap,
        setScrollingPos,
      }}
    >
      {children}
    </ArticleFilterContext.Provider>
  );
};

export default ArticleFilterContextProvider;
